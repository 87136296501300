<template>
  <div class="w-100">
        <div class="table-responsive mt-3">
              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                <thead >
                  <tr>
                    <th rowspan="2" width="3%" class="text-center">#</th>
                    <th rowspan="2" width="5%" class="text-center">Codigo</th>
                    <th rowspan="2" width="35%" class="text-center">Nombre</th>
                    <th rowspan="2" width="10%" class="text-center">UM</th>
                    <th rowspan="2" width="8%" class="text-center">Cantidad</th>
                    <th rowspan="2" width="10%" class="text-center">Imp. Unit.</th>
                    <th colspan="2" width="10%" class="text-center">Descuento</th>
                    <th rowspan="2" width="7%" class="text-center">V. Unit. <br> Neto</th>
                    <th rowspan="2" width="7%" class="text-center">P. Unit</th>
                    <th rowspan="2" width="7%" class="text-center">P. Total</th>
                    <th rowspan="2" width="5%" class="text-center"></th>
                  </tr>
                  <tr>
                    <th class="text-center">%</th>
                    <th class="text-center">Importe</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in shopping_detail" :key="it">
                  <tr>
                      <td class="align-middle text-center">{{ it + 1 }}</td>
                      <td class="align-middle text-left">{{ item.code }}</td>
                      <td class="align-middle text-left">{{ item.name +" - "+item.presentation }}</td>
                      <td class="align-middle text-center">{{ item.unit_measure }}</td>
                      <td class="align-middle text-center">
                        <input type="number" step="any" @change="EditDetail(it)" class="form-control text-center" v-model="item.quantity">
                      </td>
                      <td class="align-middle text-center">
                        <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.unit_value">
                      </td>
                      <td class="align-middle text-center">
                        <input type="number" step="any" @change="EditDetail(it)" class="form-control text-right" v-model="item.percentage_discount">
                      </td>
                      <td class="align-middle text-right">{{ item.unit_discount }}</td>
                      <td class="align-middle text-right">{{ item.net_unit_value }}</td>
                      <td class="align-middle text-right">{{ item.unit_price }}</td>
                      <td class="align-middle text-right">{{ item.total_price }}</td>
                      <td class="align-middle text-center">
                        <button type="button" @click="DeleteDetail(it)" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>  
                      </td>

                  </tr>
                </tbody>
              </table>
            </div>
  </div>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-total{
  font-size: 14px;
  font-weight: 500;
}


</style>>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from '@/assets/js/EventBus';

export default {
  name: "ShoppingDetail",
  data() {
    return {

    };
  },
  mounted() {
    EventBus.$on('AddInputDetail', (detail) => {
      this.AddDetail(detail)
    });
    EventBus.$on('ResetInputDetail', () => {
      this.input_detail = [];
    });
    EventBus.$on('cInputDetail', (detail) => {
      this.input_detail = detail;
    });
  
  },
  methods: {
    EditDetail,
    DeleteDetail,
    ...mapActions('Shopping',['mLoadEditShoppingDetail']),
    ...mapActions('Shopping',['mLoadDeleteShoppingDetail']),
    
  },
  computed: {
    ...mapState('Shopping',['shopping_detail']),
    
    
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};


function EditDetail(index) {
  let name = this.shopping_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha modificado el producto ' + name, type: 'warn'})
  this.mLoadEditShoppingDetail(index)
}

function DeleteDetail(index) {
  let name = this.shopping_detail[index].name;
  this.$notify({ group: 'alert', title: 'Sistema', text:'Se ha eliminado el producto ' + name, type: 'warn'})
  this.mLoadDeleteShoppingDetail(index)
}
</script>
